<template>
  <div class="two">
    <div style="display: none" ref="rightScroll" class="pic">
        <div>
          <span class="span1">科研创新</span>
          <span class="span2">Scientific research innovation</span>
          <div class="div1" ></div>
        <div class="div2" >科研创新</div>
          <button>
            <a href="#/ke/left">
              <span class="span3">了解更多</span>
              <span class="span4" >→</span>
            </a>
          </button>
          <div class="div3">Scientific</div>
          <div class="div4"> research</div>
          <div class="div5">innovation</div>
        </div>
    </div>
  </div>
</template>

<script>
  import "animate.css";

  export default {
    data() {
      return {
      };
    },
    name: "Two",
    methods: {},
    mounted() {
      window.addEventListener("scroll",() => {
        //变量t是滚动条滚动时，距离顶部的距离
        var a = document.documentElement.clientHeight;

        var t = document.documentElement.scrollTop || document.body.scrollTop;
        //当滚动到距离顶部200px时，返回顶部的锚点显示

        if (t+20  >4* a && t+20 < 5 * a) {
          // console.log(1111)
          this.$refs.rightScroll.style.display = "block";
          this.$refs.rightScroll.className =
              "animate__animated animate__bounce animate__bounceInLeft pic";
        } else {
          this.$refs.rightScroll.style.display = "none";
          this.$refs.rightScroll.className = "";

        }
      })
    }
  };
</script>

<style lang="less" scoped>
  .animate__animated.animate__bounce {
    --animate-duration: 1.5s;
  }
  .two {
    display: flex;
    width: 100%;
    background-image:url("../../../assets/22.png");
    background-size:100% 100vh;
    .pic {
      flex: 10;
      height: 100vh;
    }
    .span1{
      position: absolute;
      height: 3.1835vh;
      font-size: 1.9792vw;
      font-family: PingFang SC, PingFang SC-Bold;
      font-weight: 700;
      color: #ffffff;
      padding-left: 5.5729vw;
      padding-top: 6.0861vh;
    }
    .span2{
      position: absolute;
      height: 2.7154vh;
      font-size: 1.7vw;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #929292;
      padding-left: 14.6354vw;
      padding-top: 7vh;
    }
    .div1{
      width: 16.6667vw;
      height: 0.5618vh;
      background: #d7000f;
      position: absolute;
      margin-top: 12vh;
     margin-left: 5.5729vw;
    }
.div2{
  height: 7.4906vh;
  font-size: 4.1667vw;
  font-family: PingFang SC, PingFang SC-Light;
  font-weight: 300;
  color: #ffffff;
  line-height: 14.9813vh;
  letter-spacing: 0.5208vw;
  position: absolute;
  padding-top: 24.3446vh;
  padding-left: 5.1563vw;
}
    button{
      width: 11.9792vw;
      height: 6.5543vh;
      background: #d7000f;
      border-radius: 0.4167vw;
      position: relative;
      /*display: block;*/
      margin-top: 45.8801vh;
     margin-left: 5.5208vw;

    }
    .span3{
      width: 5.1563vw;
      height: 2.0599vh;
      font-size: 1.25vw;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: left;
      color: #ffffff;
    }
    .span4{
      width: 1.3542vw;
      height: 0.9363vh;
      font-size: 1.25vw;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      text-align: left;
      color: #ffffff;
    }
    .div3{
      font-size: 1.7708vw;
      font-weight: bold;
      /*position: absolute;*/
      padding-left: 5.5729vw;
      padding-top: 6.0524vh;
      color: #ffffff;
    }
    .div4{
      font-size: 1.7708vw;
      /*position: absolute;*/
      padding-left: 5.5729vw;
      padding-top: 1.4vh;
      color: #ffffff;
    }
    .div5{
      font-size: 1.7708vw;
      position: absolute;
      padding-left: 5.5729vw;
      padding-top: 1.4vh;
      color: #ffffff;
    }

  }
</style>
