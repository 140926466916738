<template>
    <div id="app" >
        <el-carousel height="89vh"  :interval="6000" arrow="always">
            <el-carousel-item     v-for="(img,index) in imgList" :key="index">
                <a target="_blank" v-bind:href="img.link">
                    <img class="cover"    v-bind:src="img.pic" >
                </a>
            </el-carousel-item>
        </el-carousel>
    </div>
</template>
<script>
  import {getBanner} from "../../../api/index"
  import 'element-ui/lib/theme-chalk/index.css'
  import ElementUI from 'element-ui'
  export default {
    name: 'Lun',
    data(){
      return{

        imgList:[
          // {url:require('../../../assets/1.png'),link:"https://hao.360.com/?src=lm&ls=n2ec577739c"},
          // {url:require('../../../assets/2.jpg'),link:"https://hao.360.com/?src=lm&ls=n2ec577739c"},
          // {url:require('../../../assets/3.jpg'),link:"https://hao.360.com/?src=lm&ls=n2ec577739c"},
        ]
      }
    },
    mounted(){
      getBanner().then((res)=>{
      console.log(res,"22222222");
      return res;
    })
        .then((res)=>{
          // 获取响应数据
          let imgList = res.data;
          this.imgList = imgList;

        })
    }
  }
</script>

<style>
    #app{
        height: 89vh;
    }
    .el-carousel__item img {
        width: 100%;
        height:89vh;
        margin: 0;
        overflow:hidden;
    }
    .cover { object-fit: cover; }
    .el-carousel__item:nth-child(2n) {
        background-color: #99a9bf;
        /*height: 500px;*/
    }
    .el-carousel__item:nth-child(2n+1) {

        background-color: #d3dce6;
        /*height: 500px;*/
    }
    .el-carousel__arrow{
        /*top: 140%!important;*/
    }
</style>

