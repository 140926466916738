<template>
  <div id="app">
    <full-page :options="options" ref="fullpage">
      <div class="section">
        <div class="slide"><Top /><One /></div>
      </div>
      <div class="section">
        <div class="slide"><Two /></div>
      </div>
      <div class="section">
        <div class="slide"><Five /></div>
      </div>
      <div class="section">
        <div class="slide"><Three /></div>
      </div>
      <div class="section">
        <div class="slide"><Six /></div>
      </div>
      <div class="section">
        <div class="slide"><Seven /></div>
      </div>
      <div class="section">
        <div class="slide"><Four /></div>
      </div>
    </full-page>
  </div>
</template>
<script>
import Vue from "vue";
import One from "../components/home/One";
import Dao from "../components/common/Dao.vue";
import Top from "../components/Top";
import Two from "../components/home/Two";
import Three from "../components/home/Three";
import Four from "../components/home/Four";
import Five from "../components/home/Five";
import Six from "../components/home/Six";
import Seven from "../components/home/Seven";
import "fullpage.js/vendors/scrolloverflow";
import VueFullPage from "vue-fullpage.js/dist/vue-fullpage";
import Index from "../components/Top/index.vue";
Vue.use(VueFullPage);
export default {
  name: "Login",
  components: {
    One,
    Two,
    Three,
    Four,
    Five,
    Six,
    Seven,
    Dao,
    Top,
    Index,
  },
  data() {
    return {
      options: {
        //整屏插件vue-fullpage选项
        licenseKey: null,
        verticalCentered: true, //定义每一页的内容是否垂直居中
        afterLoad: this.afterLoad, //滚动到某一屏后的回调函数 // scrollOverflow: true,//内容超过满屏后显示滚动条
        controlArrows: true, //是否通过箭头控制slide幻灯片
        loopHorizontal: true, //slide幻灯片是否循环滚动
        scrollBar: true, //true则是一滚动就是一整屏
        onLeave: this.onLeave, //滚动前的回调函数，
      },
    };
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  list-style: none;
}
html,
body,
div,
span,
button,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
a {
  color: #000;
  text-decoration: none;
}
a:hover {
  color: #000;
  text-decoration: none;
}
</style>
