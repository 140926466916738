<template>
  <div class="five-row">
    <div class="i">
      <header class="r-head">
        <h4 class="r-name">
          <span class="spanvv">新闻资讯</span>
          <span >News Information</span>
        </h4>
        <div class="tab-t">
          <span  v-for="item in tabT"
                :key="item.id"
                :class="{active: item.id == current}"
                @click="switchTab(item)">
            {{item.name}}
          </span>
          <a href="#/xin"><span class="span2">更多信息</span></a>
        </div>
      </header>
      <section class="r-body">
        <!-- 左边 S -->
        <div class="b-left"   >
         <div class="liu" @click="btnClick(topNew.link, i, topNew)" >
           <div class="pic">
             <img :src="topNew.pic" />
           </div>
           <div class="times">{{topNew.add_time.split(" ")[0]}}</div>
           <div class="title">
             <h5>{{topNew.name}}</h5>
             <span class="more">了解更多→</span>
           </div>
         </div>
        </div>
        <!-- 左边 E -->
        <!-- 中间 S -->
        <div class="b-middle">
          <ul class="new-list">
            <li class="list-item"  @click="btnClick(item.link, i, item)"  v-for="(item, index) in middleList" :key="index">
              <div class="times">{{item.add_time.split(" ")[0]}}</div>
              <h5 class="title">{{item.name}}</h5>
              <a href="###" class="more">查看详情</a>
            </li>
          </ul>
        </div>
        <!-- 中间 E -->
        <!-- 右边 S -->
        <div class="b-middle">
          <ul class="new-list">
            <li class="list-item" @click="btnClick(item.link, i, item)" v-for="(item, index) in rightList" :key="index">
              <div class="times">{{item.add_time.split(" ")[0]}}</div>
              <h5 class="title">{{item.name}}</h5>
              <a href="###" class="more">查看详情</a>
            </li>
          </ul>
        </div>
        <!-- 右边 E -->
      </section>
    </div>
  </div>
</template>

<script>
  import axios from "axios";
  import {getNewsCategoty,getNewsList} from "../../../api/index"
  export default {
    name: 'Five',
    data(){
      return{
        tabT:[],
        current: 1,
        page: 1,
        topNew: [],
        middleList:[],
        rightList:[],
        link: "",
        newsList:''
      }
    },
    created(){
      this.getNew();
      this.getList();
    },
    methods:{
      btnClick(link, i, item) {
        if (link == null || link == "") {
          const routeData = this.$router.resolve({
            // name: "juti",
            path:'/juti',
            // query: { key: i, cateid: this.cateid, item: JSON.stringify(item) },
          });
          window.open(routeData.href, "_blank");
          window.localStorage.setItem('item', JSON.stringify(item));
        } else {
          window.open(item.link, '_blank');
        }
      },
      // btnClick(link, i, item) {
      //   // console.log(link);
      //   if (link == null || link == "") {
      //     const routeData = this.$router.resolve({
      //       name: "juti",
      //       query: { key: i, cateid: this.cateid, item: JSON.stringify(item) },
      //     });
      //     window.open(routeData.href, "_blank");
      //   } else {
      //   }
      // },


      switchTab(item){
          this.current = item.id;
          this.getList(this.current);
          this.page = 1;
          // console.log(item)
      },
      getNew(){
        getNewsCategoty().then(res=>{
          // console.log(res);
          console.log(res.data);
          return res;
        })
            .then((res)=>{
          if(res.code == 200){
            // res.data.push({
            //   name: '更多信息',
            //   id: 10
            // });
            // console.log(res.data,"哈哈哈")
            this.tabT = res.data;
            this.getList(this.tabT[0].id)
          }
        })
      },



      getList(cateid){

        getNewsList({
          limit: 11,
          page: this.page,
          cateid
        }).then(res=>{
              console.log(res,"我是res" ,1111111)
          let data = res.data.data;
          let data1 = data.filter((e, i)=>{
            console.log("我是data1",data1)
            return i == 0;
          });
          let data2 = data.filter((e, i)=>{
            console.log("我是data2",data2)

            return i > 0&&i<6;
          });
          let data3 = data.filter((e, i)=>{
            console.log("我是data3",data3)
            return i >=6&&i<11;
          });
          this.topNew = data1[0];
          // console.log(data1[0])

          let middleList =data2;
          this.middleList = middleList;


          let rightList =data3;
          this.rightList = rightList;
        })
      }
    }
  }
</script>

<style lang="scss">
  .five-row{
    *{
      box-sizing: border-box;
    }
    .i{
      width: 98%;
      // max-width: 1920px;
      margin: 0 auto;
      height: 100vh;
      display: flex;
      flex-direction: column;
    }
    // 头部样式
    .r-head{
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 10vh;
      flex-shrink: 0;
      margin-bottom: 2.809vh;
      margin-top: 1.8727vh;
    }
    // 标题样式
    .r-name{
      position: relative;
      margin-top: 6.5543vh;
      margin-left: 5.5208vw;
      .spanvv{
        height: 2.809vh;
        font-size: 1.9792vw;
        font-family: PingFang SC, PingFang SC-Bold;
        font-weight: 700;
        color: #242424;
      }
      span{
        font-size: 1.7vw;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #929292;
      }
      &:after{
        content: '';
        position: absolute;
        left: 0;
        bottom: -1.3109vh;
        width: 16.6667vw;
        height: 0.5618vh;
        background: #d7000f;
      }
    }
    // 标签样式
    .tab-t{
      display: flex;
      span{
        width: 7.2917vw;
        height: 4.2135vh;
        border: 0.0942vw solid #d7000f;
        color: #d7000f;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0.9vw;
        margin-left: 0.5208vw;
        cursor: pointer;
        &.active{
          background-color: #d7000f;
          color:#fff;
        }
      }
    }
    .span2:hover {
      color: white;
      background-color: #d7000f;
    }
    // 内容区样式
    .r-body{
      display: flex;
      flex-grow: 1;
      justify-content: space-between;
     margin-bottom: 2vw;
    }
    // 左边新闻样式
    .b-left{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-left: 1.8229vw;
      .pic{
        margin-top: 8.427vh;
        margin-bottom: 4.6816vh;
        height: 30.8989vh;
      }
       .pic img{
        width: 30.2083vw;
        height: 31.4607vh;
      }
      .times{
        width: 7.8125vw;
        height: 4.6816vh;
        background: #d7000f;
        border-radius: 0.4125vw;
        color:#fff;
        font-size: 0.8333vw;
        display: flex;
        align-items:center;
        padding: 0 0.5208vw;
        margin-bottom: 2.809vh;
      }
      .liu:hover{
          .title h5{
            color: #d7000f;
          }
      }

      .title{
        display: flex;
        align-items: center;
        h5{
          width: 22vw;
          flex-grow: 1;
          font-size: 0.9375vw;
          color: #000;
          margin-right: 0.5208vw;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .more{
          flex-shrink: 0;
          background-color: #d7000f;
          width: 7.2917vw;
          height: 4.2135vh;
          border-radius: 0.9vw;
          display: flex;
          align-items:center;
          padding: 0 0.5208vw;
          font-size: 0.8333vw;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          color: #ffffff;
        }
      }
    }
    .b-middle, .b-right{
      height: 100%;
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      min-width: 30%;
      .new-list{
        flex-shrink: 0;
        border-left: 0.0521vw solid #ccc;
        width: 100%;
        overflow: hidden;
        .list-item{
          margin-left:2.6042vw;
        }
      }
    }
    .new-list{
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-bottom: 1.8727vh;
      .times{
        width: 5.7292vw;
        height: 3.5581vh;
        background-color: #dbdbdb;
        color: #5d5d5d;
        border-radius: 0.4167vw;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .title{
        width: 25vw;
        font-size: 1vw;
        color: #444;
        padding: 1.8727vh 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .more{
        color: #a8a8a8;
        font-size: 0.9vw;
      }
      .list-item:hover{
        .times{
          background-color: #d7000f;
          color: #fff;
        }
        .title{
          color: #d7000f;
        }
      }
    }
  }
  .five-row .new-list .times {
    font-size: 0.9vw;
  }
</style>