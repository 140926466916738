<template>
    <div class="two">
          <div style="display: none" ref="rightScroll" class="pic">
            <img src="../../../assets/part2.png" alt="" />
          </div>
          <div style="display: none" ref="leftScroll" class="text">
            <div><span class="span1">走进大伟嘉</span><span class="span2">Into VICA</span></div>
            <div class="div1"></div>
            <div class="div2" v-html="delHtmlTag(nei.content)" >{{nei.content}}</div>
            <button>
              <a href="#/zou/a">
              <span class="nihao">了解更多</span>
              </a>
                <span>→</span></button>
          </div>
          <div class="right" >
            <div v-for="(item, index) in data" :key="index">
              <countTo class="lie"
                       v-if="item.isShow"
                       ref="countTo"
                       :startVal="item.startVal"
                       :endVal="item.endVal"
                       :duration="4000"
                       :key="index"
              >
              </countTo>
              <span class="you">{{item.val1}}</span>
              <div class="xia1">{{item.val2}}</div>
              <div class="xia2"></div>
            </div>
          </div>
    </div>
</template>

<script>
  import {getEnterVica} from "../../../api/index"
  import countTo from "vue-count-to";
  import "animate.css";

  export default {
    data() {
      return {
        nei:[],
        data: [
          { startVal: 0, endVal: 500, isShow: false,val1:"强",val2:"中国农业企业" },
          { startVal: 0, endVal: 30, isShow: false,val1:"强",val2:"全国饲料企业" },
          { startVal: 0, endVal: 20, isShow: false,val1:"强",val2:"中国养猪企业" },
          { startVal: 0, endVal: 10, isShow: false,val1:"强",val2:"全国兽药企业" },
          { startVal: 0, endVal: 35, isShow: false,val1:"家",val2:"全国分公司数量" },
        ],
      };
    },
    name: "Two",
    methods: {

        // if(str!= null){
        //   return str.replace(/<[^>]+>/g,"");
        // }
        delHtmlTag(str) {
          // console.log(str)
          // if (str != null || str == undefined  ) {
          //   console.log(str.replace(/<[^>]+>/g, ""))
          //   return str.replace(/<[^>]+>/g, "");
          if (str != null || str == undefined  ) {
            let estr = str
                // .replace(/<[^>]+>/g, "")
                    .replace(/&lt;/g,"<")
                    .replace(/&gt;/g,">")
                    .replace(/&amp;/g, "")
                    .replace(/&nbsp;/g,"")
                    .replace(/nbsp;/g,"")
                    .replace(/&quot;/g, '"')
            ;
            // console.log(estr)
            return estr

          }else {
            // console.log(222);
          }

        },
  }
  ,
    mounted() {

      getEnterVica().then((res)=>{
        // console.log(res.data);
          return res;
        })
            .then((res)=>{
              // 获取响应数据
              let nei = res.data.about;
              this.nei = nei;
              // console.log(nei.content);
            })



      window.addEventListener("scroll",() => {
        //   //变量t是滚动条滚动时，距离顶部的距离
        //   var t = document.documentElement.scrollTop || document.body.scrollTop;
        //   //当滚动到距离顶部200px时，返回顶部的锚点显示
        //   if (t >= 1400 && t < 1900) {
        var a = document.documentElement.clientHeight;
        var t = document.documentElement.scrollTop || document.body.scrollTop;
        //当滚动到距离顶部200px时，返回顶部的锚点显示
        // console.log(t);
        if (t+20  > a && t+20 < 2 * a) {
          this.$refs.rightScroll.style.display = "block";
          this.$refs.rightScroll.className =
              "animate__animated animate__bounce animate__bounceInLeft pic";
          this.$refs.leftScroll.style.display = "block";
          this.$refs.leftScroll.className =
              "animate__animated animate__bounce animate__bounceInRight text";
          this.data.forEach((v, i) => {
            v.isShow = true;
          });
        } else {
          this.$refs.rightScroll.style.display = "none";
          this.$refs.rightScroll.className = "";
          this.$refs.leftScroll.style.display = "none";
          this.$refs.leftScroll.className = "";

        }
      })


    },
    components: {
      countTo,
    },
  };
</script>

<style lang="less" scoped>
    .animate__animated.animate__bounce {
        --animate-duration: 1.5s;
    }
    .two {
        background-color: #F5F5F5;
        display: flex;
        .pic {
            flex: 3.5;
            height: 100vh;
        }
        .pic img{
            height: 100vh;
        }
        .text {
            flex: 5;
          width: 34.2188vw;
        }
        .span1{
            height: 3.1835vh;
            font-size: 1.9792vw!important;
          font-family: PingFang SC, PingFang SC-Light;
            font-weight:700;
            color: #242424;
            position: absolute;
            margin-top: 10.0187vh;
            margin-left: 4.2708vw;
        }
        .span2{
            height: 2.4345vh;
            font-size: 1.7vw!important;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            color: #aeaeae;
            position: absolute;
            margin-top: 10.6187vh;
           margin-left: 14.6708vw;
        }

      /deep/p {
        line-height: 1.1vw!important;
        text-indent:0!important;
        span{
        font-size: 0.9vw!important;
          font-family: "Microsoft YaHei"!important;
          line-height: 0 !important;
        font-weight: 300;
        color: #000000!important;
      }}

        .div1{
            width: 13.5417vw;
            height: 0.5618vh;
            background: #d7000f;
            position: absolute;
            margin-top: 14.9813vh;
            margin-left: 4.2188vw;
        }
        .div2{
            position: absolute;
            width: 33.8542vw;
            margin-top: 20.8629vh;
            margin-left: 4.2188vw;
            font-size: 0.8444vw;
            font-family: PingFang SC, PingFang SC-Light;
            font-weight: 300;
            color: #000000;
            line-height: 2.4345vh;
        }
        button{
            width: 8.8542vw;
            height: 5.2434vh;
            background: #d7000f;
            border-radius: 0.4167vw;
            position: absolute;
            margin-left:4.2188vw ;
            margin-top: 70.633vh;
        }
      button span{
        color: white!important;
      }

        .right{
            flex: 1.5;
            padding-top: 6.0861vh;
        }
      .lie{
            align-items: center;
            height: 4.2135vh;
            width: 5.2083vw;
            font-size: 2.6042vw;
            font-family: TimesNewRomanPSMT, TimesNewRomanPSMT-Regular;
            font-weight: 400;
            color: #d7000f;
        }
        .you{
            position: absolute;
            width: 1.8333vw;
            height: 1.4981vh;
            font-size: 0.8333vw!important;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            text-align: left;
            color: #d7000f;
            margin-left: 0.8854vw;
            margin-top: 2.6217vh;
        }
        .xia1{
            padding-top: 1.2172vh;
            width: 8.8542vw;
            height: 1.5918vh;
            font-size: 0.9375vw;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight:500;
            color: #d7000f;
            letter-spacing: 0.1625vw;
        }
        .xia2{
            width: 7.2917vw;
            height: 0.0936vh;
            opacity: 0.3;
            background-color:black;
            margin-bottom: 2.809vh;
            margin-top: 3.0899vh;
        }

    }
</style>
