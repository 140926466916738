<template>
    <div class="container">
        <div class="ding">
            <div class="one">
              <span class="biao" >产业布局</span>
              <span class="ti1" >Industrial layout</span>
              <div class="xian"></div>
                <div class="div1">生物育种</div>
              <button class="button1">
                <router-link
                        :to="{ path: '/chan/first'} "
                >
                  <span class="span3">了解更多</span>
                </router-link>
                <span class="span4">→</span></button>
            </div>
            <div class="two">
              <div class="div1">生物饲料</div>
              <button class="button1">
                <router-link
                        :to="{ path: '/chan/second'} "
                >
                  <span class="span3">了解更多</span>
                </router-link><span class="span4">→</span>
              </button>
            </div>
            <div class="three">
              <div class="div1">生物兽药</div>
              <button class="button1">
                <a href="#/chan/third">
                  <span class="span3">了解更多</span>
                </a><span class="span4">→</span>
              </button>
            </div>
            <div  class="four">
              <div class="div1">健康养殖</div>
              <button class="button1">
                <a href="#/chan/fourth">
                  <span class="span3">了解更多</span>
                </a><span class="span4">→</span></button>
            </div>
            <div class="five">
              <div class="div1">产业服务</div>
              <button class="button1">
                <a  href="#/chan/fifth">
                  <span class="span3">了解更多</span>
                </a><span class="span4">→</span>
              </button>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: "Xuan",
  }
</script>

<style scoped>
    .container{
        height: 100vh;
        background-image: url("../../../assets/9.png");
      background-repeat: no-repeat;
      background-size:100% 100%;
    }
    .ding{
      height: 100vh;
        display: flex;
    }

    .one{
        width: 20%;
        height: 100vh;
        position: relative;
    }
    .one .biao{
      height: 3.3708vh;
      font-size: 2.0833vw;
      font-family: Adobe Heiti Std, Adobe Heiti Std-R;
      font-weight: 700;
      color: #242424;
      position: absolute;
      margin-top: 7.9588vh;
      margin-left:5.5729vw ;
      z-index:99999;
    }
    .ti1{
      z-index:99999;
      width: 18.75vw;
      height: 3.3708vh;
      font-size: 1.7vw;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #929292;
      position: absolute;
      margin-top: 9.1vh;
      margin-left:14.7396vw;
    }
    .xian{
      z-index:99999;
      width: 16.6667vw;
      height: 0.6554vh;
      background: #d7000f;
      position: absolute;
      margin-top: 13.0596vh;
      margin-left:5.5729vw;
    }
    .one .div1{
      height: 7.8652vh;
      font-size: 2.0833vw;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      color: #595757;
      position: absolute;
      margin-top:27.1536vh;
      margin-left:6.15vw;
    }
    .one .span1{
      height: 7.8652vh;
      font-size: 2.0833vw;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      color: #595757;
      position: absolute;
      margin-top:30.8989vh;
      margin-left:3.9063vw;
    }
    .one .button1{
      width: 8.8542vw;
      height: 5.1498vh;
      border: 0.1563vw solid #d7000f;
      border-radius: 0.1563vw;
      position: absolute;
      margin-top:58.6142vh;
      margin-left:5.3125vw;
      background-color: transparent;
    }
    .one .span3{
      height: 1.779vh;
      font-size: 1.0417vw;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #d7000f;
    }
    .one .span4{
      height: 0.8427vh;
      font-size: 1.0417vw;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #d7000f;
    }
    .one:hover,.one:active {
        background-image: url("../../../assets/组 1.png");
        background-size: 100% 100%;
        background-repead: no-repead;
    }
    .one:hover .div1{
      color: white;
    }
    .one:hover .span1{
      color: white;
    }
    .one:hover .button1{
      background: #d7000f;
     }
    .one:hover .span3{
      color: white;
    }
    .one:hover .span4{
      color: white;
    }

    .two{
        width: 20%;
        height: 100vh;
        text-align: center;
        position: relative;
    }
    .two .div1{
      height: 7.8652vh;
      font-size: 2.0833vw;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      color: #595757;
      position: absolute;
      margin-top:27.1536vh;
      margin-left:6.0708vw;
    }
    .two .button1{
      width: 8.8542vw;
      height: 5.1498vh;
      border: 0.1563vw solid #d7000f;
      border-radius: 0.1563vw;
      margin-top:58.6142vh;
      background-color: transparent;

    }
    .two .span3{
      height: 1.779vh;
      font-size: 1.0417vw;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #d7000f;
    }
    .two .span4{
      height: 0.8427vh;
      font-size: 1.0417vw;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #d7000f;
    }

    .two:hover,.two:active {
        background-image:url("../../../assets/组2.png");
        background-size: 100% 100%;
        background-repead: no-repead;
    }
    .two:hover .div1{
      color: white;
    }
    .two:hover .button1{
      background: #d7000f;
    }
    .two:hover .span3{
      color: white;
    }
    .two:hover .span4{
      color: white;
    }
  .three{
    width: 20%;
    height: 100vh;
    text-align: center;
    position: relative;
  }
    .three .div1{
      height: 7.8652vh;
      font-size: 2.0833vw;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      color: #595757;
      position: absolute;
      margin-top:27.1536vh;
      margin-left:6.1917vw;
    }
    .three .button1{
      width: 8.8542vw;
      height: 5.1498vh;
      border: 0.1563vw solid #d7000f;
      border-radius: 0.1563vw;
      margin-top:58.6142vh;
      background-color: transparent;
    }
    .three .span3{
      height: 1.779vh;
      font-size: 1.0417vw;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #d7000f;
    }
    .three .span4{
      height: 0.8427vh;
      font-size: 1.0417vw;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #d7000f;
    }
    .three:hover,.three:active {
      background-image:url("../../../assets/组3.png");
      background-size: 100% 100%;
      background-repead: no-repead;
    }
    .three:hover .div1{
      color: white;
    }
    .three:hover .button1{
      background: #d7000f;
    }
    .three:hover .span3{
      color: white;
    }
    .three:hover .span4{
      color: white;
    }

  .four{
    width: 20%;
    height: 100vh;
    text-align: center;
    position: relative;
  }
    .four .div1{
      height: 7.8652vh;
      font-size: 2.0833vw;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      color: #595757;
      position: absolute;
      margin-top:27.1536vh;
      margin-left:5.35vw;
    }
    .four .button1{
      width: 8.8542vw;
      height: 5.1498vh;
      border: 3px solid #d7000f;
      border-radius: 0.1563vw;
      margin-top:58.6142vh;
      background-color: transparent;
    }
    .four .span3{
      height: 1.779vh;
      font-size: 1.0417vw;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #d7000f;
    }
    .four .span4{
      height: 0.8427vh;
      font-size: 1.0417vw;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #d7000f;
    }
    .four:hover,.four:active {
      background-image:url("../../../assets/组4.png");
      background-size: 100% 100%;
      background-repead: no-repead;
    }
    .four:hover .div1{
      color: white;
    }
    .four:hover .button1{
      background: #d7000f;
    }
    .four:hover .span3{
      color: white;
    }
    .four:hover .span4{
      color: white;
    }
  .five{
    width: 20%;
    height: 100vh;
    text-align: center;
    position: relative;
  }
    .five .div1{
      height: 7.8652vh;
      font-size: 2.0833vw;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      color: #595757;
      position: absolute;
      margin-top:27.1536vh;
      margin-left:5.35vw;
    }
    .five .button1{
      width: 8.8542vw;
      height: 5.1498vh;
      border: 0.1563vw solid #d7000f;
      border-radius: 0.1563vw;
      margin-top:58.6142vh;
      background-color: transparent;
    }
    .five .span3{
      height: 1.779vh;
      font-size: 1.0417vw;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #d7000f;
    }
    .five .span4{
      height: 0.8427vh;
      font-size: 1.0417vw;
      font-family: PingFang SC, PingFang SC-Regular;
      font-weight: 400;
      color: #d7000f;
    }
    .five:hover,.five:active {
      background-image:url("../../../assets/组5.png");
      background-size: 100% 100%;
      background-repead: no-repead;
    }
    .five:hover .div1{
      color: white;
    }
    .five:hover .button1{
      background: #d7000f;
    }
    .five:hover .span3{
      color: white;
    }
    .five:hover .span4{
      color: white;
    }
</style>
