<template>
    <header>
        <Sousuo :searchActive.sync="searchActive"></Sousuo>


        <div class="top">
            <li class="left"></li>
            <li class="right"></li>
        </div>
        <div class="header">
            <div class="inner">
                <div class="logo">
                    <a href="../../../public/index.html">
                        <img src="../../assets/LOGO.png" alt="" />
                    </a>
                </div>
                <div class="tab">
                    <!--class="active"-->
                    <li class="active">
                        <a class="p-a" href="#/login">首页</a>
                    </li>
                    <li>
                        <router-link
                                :to="{ path: '/zou/a', query: { id: 'a' } }"
                                class="p-a"
                                :class="{
                                active:
                                active == 'a'
                                ||active == 'b'
                                ||active == 'g'
                                ||active == 'c'
                                ||active == 'e'
                                 ||active == 'f'
                                  ||active == 'meng'

                            }"
                        >
                            走进大伟嘉
                        </router-link>
                        <div class="sub">
                            <div class="s-i">
                                <a
                                        class="s"
                                        href="###"
                                        style="font-weight: 600"
                                >走进大伟嘉</a
                                >
                                <span>丨</span>
                                <router-link
                                        class="s-a"
                                        :to="{ path: '/zou/a', query: { id: 'a' } }"
                                        :class="{  active: active == 'a' }"
                                        @click="active = 'a'"
                                >大伟嘉简介</router-link
                                >
                                <router-link
                                        class="s-a"
                                        :to="{ path: '/zou/b', query: { id: 'b' } }"
                                        :class="{ active: active == 'b' }"
                                        @click="active = 'b'"
                                >董事长介绍</router-link
                                >
                                <router-link
                                        class="s-a"
                                        :to="{ path: '/zou/g', query: { id: 'g' } }"
                                        :class="{ active: active == 'g' }"
                                        @click="active = 'g'"
                                >领导关怀</router-link
                                >
                                <router-link
                                        class="s-a"
                                        :to="{ path: '/zou/c', query: { id: 'c' } }"
                                        :class="{ active: active == 'c' }"
                                        @click="active = 'c'"
                                >企业荣誉</router-link
                                >
                                <!-- <a class="s-a" href="#/zou/a" v-for="item in data" :key="item.id">{{item.title}}</a> -->
                                <router-link
                                        class="s-a"
                                        :to="{ path: '/zou/e', query: { id: 'e' } }"
                                        :class="{ active: active == 'e' }"
                                        @click="active = 'e'"
                                >产品中心</router-link
                                >
                                <router-link
                                        class="s-a"
                                        :to="{ path: '/zou/f', query: { id: 'f' } }"
                                        :class="{ active: active == 'f' }"
                                        @click="active = 'f'"
                                >发展历程</router-link
                                >
                                <router-link
                                        class="s-a"
                                        href="#/zou/d/meng"
                                        :to="{ path: '/zou/d/meng', query: { id: 'meng' } }"
                                        :class="{ active: active == 'meng' }"
                                        @click="active = 'meng'"
                                >企业文化</router-link
                                >
                            </div>
                        </div>
                    </li>
                    <li>
                        <router-link
                                class="p-a"
                                :to="{ path: '/xin', query: { id: 'xin' } }"
                                :class="{
                                active:active == 'xin'
                                ,
                            }"

                        >新闻资讯</router-link
                        >
                        <div class="sub">
                            <div class="s-i">
                                <a
                                        class="s"
                                        href="###"
                                        style="font-weight: 600"
                                >新闻资讯</a
                                >
                                <span>丨</span>
                                <router-link
                                        @click.native="updateId(i)"

                                        :to="{ name: 'xin',query: { id: 'xin' }  }"

                                        class="s-a"
                                        v-for="(item1, i) in dat"
                                        :key="item1.id"
                                >{{ item1.name }}</router-link
                                >
                            </div>
                        </div>
                    </li>
                    <li>
                        <router-link
                                :to="{ path: '/chan', query: { id: 'first' } }"
                                class="p-a"
                                :class="{
                                active:
                                active == 'first'
                                ||active == 'second'
                                ||active == 'third'
                                ||active == 'fourth'
                                ||active == 'fifth'
                                ,
                            }"
                        >产业布局</router-link
                        >
                        <div class="sub">
                            <div class="s-i">
                                <a
                                        class="s"
                                        href="###"
                                        style="font-weight: 600"
                                >产业布局</a
                                >
                                <span>丨</span>
                                <!--:to="{ path: '/chan', query: { id: 'first' } }"-->
                                <router-link
                                        class="s-a"
                                        :to="{ path: '/chan/first', query: { id: 'first' } }"
                                        :class="{ active: active == 'first' }"
                                        @click="active = 'first'"
                                >生物育种</router-link
                                >
                                <router-link
                                        class="s-a"
                                        :to="{ path: '/chan/second', query: { id: 'second' } }"
                                        :class="{ active: active == 'second' }"
                                        @click="active = 'second'"
                                >生物饲料</router-link
                                >
                                <router-link
                                        class="s-a"
                                        :to="{ path: '/chan/third', query: { id: 'third' } }"
                                        :class="{ active: active == 'third' }"
                                        @click="active = 'third'"
                                >生物兽药</router-link
                                >
                                <router-link
                                        class="s-a"
                                        :to="{ path: '/chan/fourth', query: { id: 'fourth' } }"
                                        :class="{ active: active == 'fourth' }"
                                        @click="active = 'fourth'"
                                >健康养殖</router-link
                                >
                                <router-link
                                        class="s-a"
                                        :to="{ path: '/chan/fifth', query: { id: 'fifth' } }"
                                        :class="{ active: active == 'fifth' }"
                                        @click="active = 'fifth'"
                                >产业服务</router-link
                                >
                            </div>
                        </div>
                    </li>
                    <li>
                        <router-link
                                :to="{ path: '/ke/left', query: { id: 'left' } }"
                                class="p-a"
                                :class="{ active: active == 'left'||active == 'center'||active == 'right' }"
                                @click="active = 'left'"
                        >科研创新</router-link
                        >
                        <div class="sub">
                            <div class="s-i">
                                <a
                                        class="s"
                                        href="###"
                                        style="font-weight: 600"
                                >科研创新</a
                                >
                                <span>丨</span>
                                <router-link
                                        class="s-a"
                                        :to="{ path: '/ke/left', query: { id: 'left' } }"
                                        :class="{ active: active == 'left' }"
                                        @click="active = 'left'"
                                >科研体系</router-link
                                >
                                <router-link
                                        class="s-a"
                                        :to="{ path: '/ke/center', query: { id: 'center' } }"
                                        :class="{ active: active == 'center' }"
                                        @click="active = 'center'"
                                >科研实力</router-link
                                >
                                <router-link
                                        class="s-a"
                                        :to="{ path: '/ke/right', query: { id: 'right' } }"
                                        :class="{ active: active == 'right' }"
                                        @click="active = 'right'"
                                >科研成果</router-link
                                >
                            </div>
                        </div>
                    </li>
                    <li>
                        <router-link
                                :to="{ path: '/ren', query: { id: 'ren' } }"
                                class="p-a"
                                href="#/ren"
                                :class="{ active: active == 'ren' }"
                                @click="active = 'ren'"
                        >人才中心</router-link
                        >
                    </li>
                    <li>
                        <router-link
                                :to="{ path: '/she', query: { id: 'she' } }"
                                class="p-a"
                                href="#/she"
                                :class="{ active: active == 'she' }"
                                @click="active = 'she'"
                        >社会责任</router-link
                        >
                    </li>

                    <li>
                        <router-link
                                :to="{ path: '/lian', query: { id: 'lian' } }"
                                class="p-a"
                                href="#/lian"
                                :class="{ active: active == 'lian' }"
                                @click="active = 'lian'"
                        >联系我们</router-link
                        >
                    </li>
                </div>
                <div class="search" @click="searchActive = 'show'">
                    <img src="../../assets/搜索.png" />
                </div>
            </div>
        </div>
    </header>
</template>

<script>
  import { getAboutList, getNewsCategoty } from "../../api/index";
  import Sousuo from "../../components/common/Sousuo";
  export default {
    name: "Top",
    components: { Sousuo },
    data() {
      return {
        data: [],
        dat: [],
        searchActive: "",
      };
    },
    methods: {
      updateId(i) {
        // console.log("????")
        // console.log(i,"???")
        this.$bus.$emit("updateId", i);
      },
    },
    mounted() {
      getAboutList().then((res) => {
        // console.log(res.data);
        let data = res.data;
        this.data = data;
      }),
          getNewsCategoty().then((resa) => {
            let dat = resa.data;
            this.dat = dat;
          });
    },
  };
</script>

<style lang="less">
    a:hover {
        color: #d3001c;
    }
    header {
        position: relative;
        /*display: flex;*/
        display: block;
        .top {
            height: 2vh;
            display: flex;
            li {
                float: right;
            }
            .left {
                width: 30%;
                background-color: #d7000f;
            }
            .right {
                flex: 1;
                background-color: #003fa8;
            }
        }
        .header {
            background-color: #f6f5fa;
            position: relative;
            z-index: 10;
            height: 9vh;
            .inner {
                width: 100%;
                margin: 0 auto;
                display: flex;
            }
            .logo {
                width: 30%;
                display: flex;
                align-items: center;
                img {
                    height: 5.5vh;
                    margin-left: 100%;
                }
            }
            .tab {
                margin: 0 2%;
                flex: 1;
                display: flex;
                justify-content: space-between;
                .p-a {
                    font-family: PingFang SC, PingFang SC-Regular;
                    display: flex;
                    height: 9vh;
                    padding: 0 0;
                    align-items: center;
                    justify-content: center;
                    border-bottom: 0.2809vh solid transparent;
                    color: #000000;
                    font-size: 1.0417vw;
                }
                li {
                    &.active,
                    &:hover {
                        .p-a {
                            color: red;
                            /*border-bottom: 3px solid red;*/
                        }
                        .sub {
                            display: flex;
                            opacity: 1;
                        }
                    }
                }
                .sub {
                    position: absolute;
                    display: none;
                    opacity: 0;
                    left: 0;
                    right: 0;
                    top: 100%;
                    background-color: rgba(255, 255, 255, 0.8);
                    .s-i {
                        width: 100%;
                        margin: 0 auto;
                        display: flex;
                        padding-left: 30%;
                        align-items: center;
                    }
                    .s-a {
                        display: inline-flex;
                        height: 8vh;
                        align-items: center;
                        justify-content: center;
                        padding: 0 1.0417vw;
                        font-size: 0.8333vw;
                        color: #666;
                        &:hover {
                            background-color: #d7000f;
                            color: white;
                        }
                    }
                }
            }
            .search {
                width: 7%;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                img {
                    width: 30%;
                }
            }
        }
    }
</style>
